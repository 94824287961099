<template>

    <div id="app">
      <TonConnectUIProvider :options="options">
      <div >
        <Header  :pos="this.$route.meta.pos"> </Header>
      </div>
      </TonConnectUIProvider>
        <!-- 背景音乐 -->

    <div class="page-promotion flex-col">
      <div class="butt-aud" @click="audioPlayOrPause()">
        <img v-show="playFlag" class="audio-on img" src="@/assets/images/btn3.png" alt="">
        <img v-show="!playFlag" class="audio-off img" src="@/assets/images/btn3-1.png" alt="">
      </div>
        <audio ref="audio" autoplay  preload="auto" class="aud" @ended="playNextAudio">
          <source :src="currentTracks" />
        </audio>
    </div>
        <!-- 背景音乐 end -->
      <router-view :callParentMethod="audioPlayOrPause" />
    </div>

</template>

<script>
// 在 Vue 组件的 script 部分引入
import {  TonConnectUIProvider   } from "@townsquarelabs/ui-vue";
import Header from '@/components/header.vue'
import { mapState, mapMutations } from 'vuex';
export default {
  computed: {
    ...mapState(['playFlag'])
  },
  components: {
    Header,
    TonConnectUIProvider,
  },
  data() {
    return {
      options : {
        manifestUrl:"https://akefishweb.akedo.fun/connect-manifest.json",
      },
      headerShow: true,
      // 背景音乐
      clickMusicBtn: false,
      audioTracks: [
        'https://s3.ap-southeast-1.amazonaws.com/backend.akedo.fun/akedo/video/BGM+(1).mp3',
        'https://s3.ap-southeast-1.amazonaws.com/backend.akedo.fun/akedo/video/BGM+(2).mp3',
        'https://s3.ap-southeast-1.amazonaws.com/backend.akedo.fun/akedo/video/BGM+(3).mp3',
        'https://s3.ap-southeast-1.amazonaws.com/backend.akedo.fun/akedo/video/BGM+(4).mp3',
        'https://s3.ap-southeast-1.amazonaws.com/backend.akedo.fun/akedo/video/BGM+(5).mp3',
      ],
      currentTrackIndex: 1,
      currentTracks:  'https://s3.ap-southeast-1.amazonaws.com/backend.akedo.fun/akedo/video/BGM+(1).mp3',
    }
  },

    async mounted () {
      this.audioPlayOrPause()
      document.addEventListener("visibilitychange", (e) => { // 兼容ios微信手Q
        if (this.clickMusicBtn) { // 点击了关闭音乐按钮
          if (this.playFlag) {
            this.audioAutoPlay();
              this.updatePlayFlag(true);
          } else {
            this.audioPause();
            this.updatePlayFlag(false);
          }
      
          text.innerHTML = e.hidden;
          if (e.hidden) {  // 网页被挂起
            this.audioAutoPlay();
              this.updatePlayFlag(true);
          } else { // 网页被呼起
            this.audioPause();
            this.updatePlayFlag(false);
          }
        } else { // 未点击关闭音乐按钮
          if (this.playFlag) {
            this.audioPause();
            this.updatePlayFlag(false);
          } else {
            this.audioAutoPlay();
              this.updatePlayFlag(true);
          }
      
          text.innerHTML = e.hidden;
          if (e.hidden) {  // 网页被挂起
            this.audioPause();
            this.updatePlayFlag(false);
          } else { // 网页被呼起
            this.audioAutoPlay();
              this.updatePlayFlag(true);
          }
        }
      });
    },
  beforeCreate () {
  },
  mounted() {
  },
  watch: {
    $route(to, from) {
      if (this.$route.path == "/"  ) {
        this.headerShow = false;
      } else {
        this.headerShow = true;
      }
    },
  },
  methods: {
    ...mapMutations([
      "updatePlayFlag",
    ]),
    // 背景音乐
      audioPlayOrPause() {
        this.clickMusicBtn = !this.clickMusicBtn;

        if (this.playFlag) {
          this.audioPause();
          this.updatePlayFlag(false);
        } else {
          this.audioAutoPlay();
          this.updatePlayFlag(true);
        }
        console.log(this.playFlag)
      },
      audioPause() {
        let audio = this.$refs.audio
        audio.pause();
        document.addEventListener("WeixinJSBridgeReady", function () {
          audio.pause();
        }, false);
      },
      audioAutoPlay() {
        let audio = this.$refs.audio
        audio.play();
        document.addEventListener("WeixinJSBridgeReady", function () {
          audio.play();
        }, false);
      },
        playNextAudio() {
          if (this.currentTrackIndex >= this.audioTracks.length) {
            // 所有音乐播放结束
            this.currentTrackIndex = 0;
          }
          const audio = this.$refs.audio
          this.currentTracks = this.audioTracks[this.currentTrackIndex];
          audio.load();
          audio.play()
              .catch(error => {
                console.error(error);
                // 处理播放错误，例如用户没有交互或其他
              });

          this.currentTrackIndex++;
        }

  }
  
  
}
</script>
<style lang="scss">
</style>
