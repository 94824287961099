import { get, post, put, destroy } from "./http";

const Api = {
  Home: {
    Home() {
      return get(`/api/Home/index`);
    },
    Inside(id) {
      return get(`/api/Home/inside?id=${id}`);
    },
    Related(id) {
      return get(`/api/Home/related?id=${id}`);
    },
  },
};

export default Api;
