<template>
        <div class="h-btns">
          <span class="btn login" v-if="userFriendlyAddress" @click="sendTransaction()">
             <img src="@/assets/images/logo.png" alt="" />
          </span>
          <span class="btn login" v-else @click="openModal()">
            <img src="@/assets/images/log.png" alt="" />
          </span>
<!--          <TonConnectButton className="custom-class" :styles="{ color: 'red',float: 'right' }">
            &lt;!&ndash; the real button is down here &ndash;&gt;
          </TonConnectButton>-->
        </div>

</template>

<script>
// 在 Vue 组件的 script 部分引入
import {  useTonConnectUI,useTonAddress,useTonConnectModal   } from "@townsquarelabs/ui-vue";
export default {
  components: {

  },
  props: ["pos"],
  data() {
    return {
      userFriendlyAddress:false,
      tonConnectUI: null,
      state: {},
      open: null,
      close: null
    };
  },
/*  setup() {
    const [tonConnectUI] = useTonConnectUI();
    const sendTransaction = () => {
      tonConnectUI.disconnect();
    };

    return {  sendTransaction, };
  },*/
  created() {

    this.initData();
  },
  activated() {
    this.initData();
  },
  mounted() {
    // 初始化 tonConnectUI
    [this.tonConnectUI] = useTonConnectUI();
    const { state, open, close } = useTonConnectModal();
    this.state = state;
    this.open = open;
    this.close = close;
  },
  methods: {
    initData() {
     this.userFriendlyAddress=useTonAddress();
    },
    sendTransaction() {
      this.tonConnectUI.disconnect();
    },
    openModal() {
      this.open();
    },

  },

};
</script>
<style scoped>
.header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
}

.logo {
  cursor: pointer;
  font-size: 0;
  width: 100px;
  display: block;
}

.logo img {
  display: block;
  width: 100%;
}
.header .wp {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.header .r {
  display: flex;
  align-items: center;
}
.h-box {
  background-color: rgba(59, 39, 147, 0.6);
  border-radius: 21px 0px 21px 0px;
  padding: 6px;
}
.h-box .inner {
  border-radius: 21px 0px 21px 0px;
  border: 1px solid rgba(163, 149, 227, 0.11);
  display: flex;
  align-items: center;
  padding: 6px 10px 6px 15px;
}
.h-name {
  line-height: 40px;
  font-weight: bold;
  font-size: 20px;
  color: #ffffff;
  padding: 0 20px;
  text-transform: uppercase;
  background-color: #eeab40;
  border-radius: 8px;
  border: 1px solid #ffffff;
}
.nav li {
  display: inline-block;
}
.nav .t {
  line-height: 40px;
  font-weight: bold;
  font-size: 20px;
  color: #ffffff;
  padding: 0 20px;
  text-transform: uppercase;
  transition: 0.3s;
}
.nav .t:hover {
  color: #eeab40;
}
.h-btns .login {
  display: block;
  width: 70px;
  margin-left: 52px;
  transition: 0.3s;
}
.h-btns .login:hover {
  opacity: 0.8;
}
.h-btns .login img {
  display: block;
  width: 100%;
}
</style>
