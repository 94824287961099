<template>
  <div class="header">
    <div class="wp">
      <router-link to="/" class="logo">
        <img src="@/assets/images/logo.png" alt="" />
      </router-link>

      <div class="r">
        <div class="h-box">
          <div class="inner">
            <ul class="nav">
              <li :class="pos == 0 ? 'h-name' : ''">
                <div class="v1  "  >
                  <router-link to="/" class="t">game</router-link>
                </div>
              </li>
              <li :class="pos == 1 ? 'h-name' : ''">
                <div class="v1">
                  <router-link to="/farming" class="t">Farming</router-link>
                </div>
              </li>
              <li :class="pos == 2 ? 'h-name' : ''">
                <div class="v1">
                  <router-link to="/marketplace" class="t">MARKETPLACE</router-link>
                </div>
              </li>
              <li :class="pos == 3 ? 'h-name' : ''">
                <div class="v1">
                  <router-link to="/about" class="t">ABOUT US</router-link>
                </div>
              </li>
            </ul>
          </div>
        </div>
<!--        <div class="h-btns">
          <span class="btn login">
             <img src="@/assets/images/log.png" alt="" />
          </span>

        </div>-->
        <login-vue-ui></login-vue-ui>
      </div>
    </div>
  </div>
</template>

<script>
import loginVueUi from '@/components/login-vue-ui.vue'
export default {
  components: {
    loginVueUi

  },
  props: ["pos"],
  data() {
    return {};
  },
};
</script>
<style scoped>
.header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
}

.logo {
  cursor: pointer;
  font-size: 0;
  width: 100px;
  display: block;
}

.logo img {
  display: block;
  width: 100%;
}
.header .wp {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.header .r {
  display: flex;
  align-items: center;
}
.h-box {
  background-color: rgba(59, 39, 147, 0.6);
  border-radius: 21px 0px 21px 0px;
  padding: 6px;
}
.h-box .inner {
  border-radius: 21px 0px 21px 0px;
  border: 1px solid rgba(163, 149, 227, 0.11);
  display: flex;
  align-items: center;
  padding: 6px 10px 6px 15px;
}
.h-name {
  line-height: 40px;
  font-weight: bold;
  font-size: 20px;
  color: #ffffff;
  padding: 0 20px;
  text-transform: uppercase;
  background-color: #eeab40;
  border-radius: 8px;
  border: 1px solid #ffffff;
}
.nav li {
  display: inline-block;
}
.nav .t {
  line-height: 40px;
  font-weight: bold;
  font-size: 20px;
  color: #ffffff;
  padding: 0 20px;
  text-transform: uppercase;
  transition: 0.3s;
}
.nav .t:hover {
  color: #eeab40;
}
.nav .h-name .t:hover {
  color: #ffffff;
}
.h-btns .login {
  display: block;
  width: 70px;
  margin-left: 52px;
  transition: 0.3s;
}
.h-btns .login:hover {
  opacity: 0.8;
}
.h-btns .login img {
  display: block;
  width: 100%;
}
</style>
