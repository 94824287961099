import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    playFlag: false,
  },
  getters: {
  },
  mutations: {
    updatePlayFlag(state, newValue) {
      state.playFlag = newValue;
    }
  },
  actions: {
  },
  modules: {

  }
})
