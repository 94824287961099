import Vue from 'vue'
Vue.filter("formatnumber",(value)=>{
  if(!value) return '0';	
	var intPart =  String(value.toFixed(2)) || '0.00'; //获取整数部分
  var arr = intPart.split('.');
  if(arr.length>1) {
    var firstnum = Number(arr[0]);
    var last = arr[1];
    var intPartFormat = firstnum.toLocaleString() + '.' + last; //将整数部分逢三一断
  }else {
    return intPart.toLocaleString();
  }
	return intPartFormat;
})
Vue.filter("formatint",(value)=>{
  if(!value) return '0';	
  return parseInt(Number(value)).toLocaleString();
})